import React from "react"
import { Link } from "gatsby"
const StoryData = ({date, author, category, tags, finished, pageViews}) => {
    return (
        <>
        <p><b>Autor</b></p>
        <p><Link to={ `/authors/${author.slug}`}>{author.name}</Link></p>
        {((author.donation !== null && author.donation !== "") ?<p><i class="fas fa-coins" id="card-icon"></i>{` `}<a href={author?.donation}  target="_blank" rel="noreferrer">Apoya al autor</a>

        <Link to={`/pages/donar`}>{`      `}¿Por qué apoyar?</Link>

        </p>:<></>)}
        <p><b>Fecha de publicación</b></p>
        <p>{new Date(date).toLocaleDateString('es-mx',{year: 'numeric',month: 'long',day: 'numeric'})}</p>
        <p><b>Categoría</b></p>
        <p><Link to={`/categories/${category.slug}`}>{category.name}</Link></p>
        <p><b>Etiqueta(s)</b></p>
        {tags?.map((tag)=>(
        <p><Link to={`/tags/${tag.slug}`}>{tag.name}</Link>{`  `}</p>
        ))}
        <p><b>Estatus</b></p>
        <p>{(finished?"Terminado":"Publicándose")}</p>
        <p><b>Vistas</b></p>
        <p>{(pageViews>0?pageViews:"-")}</p>
        </>
    )
}

export default StoryData