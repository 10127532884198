import React from "react"
import { graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Menu from '../components/menu'
import PostsGrid from '../components/posts-grid'
import Footer from '../components/footer'
import Comments from '../components/comments'
import Seo from '../components/seo'
import FeaturedImage from '../components/featured-image'
import FormattedText from '../components/formatted-text'
import StoryData from '../components/story-data'
import StoryChapters from '../components/story-chapters'
import AdSense from 'react-adsense'


import {siteUrl, adClient, adSlotInArticle} from '../utils/config'
import {getRandomFromArray,getRelatedFromArray} from '../utils/utils'

const Story = ({ data }) => {
    const story = data.strapiStories
    const allposts = data.allStrapiPosts.edges
    const allStories = data.allStrapiStories.edges
    const postAndStories = allposts.concat(allStories)
    const tags = story.tags
    const category = story.category.slug
    const relatedPosts = getRelatedFromArray(category, tags, postAndStories)
    const morePosts = getRandomFromArray(relatedPosts, 3)
    const seo = {
      metaTitle: story.title,
      metaDescription: "",
      shareImage: story.image,
      article: true,
    }
    let allPageViews = 0
    /*/
    data.allPageViews.nodes.forEach((page)=>{
      allPageViews += page.totalCount
    })
    /*/
    return (
    <>
    <Seo seo ={seo}/>
    <Container fluid="md">
        <Row>
        <Col>
            <Menu/>
        </Col>
        </Row>
        <Row id ="content-row">
        <Col>
        <Row>
        <Col>
            <h1>{story.title}</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <Row>
                <FeaturedImage alt={story.title || ""} image={story.image || ""} post={true}/>
                </Row>
                <StoryData date={story.date} author={story.author} category={story.category} tags={story.tags} finished ={story.finished} pageViews={allPageViews}/>
            </Col>
            <Col xl={8}>
                <h2>Descripción</h2>
                <FormattedText  content={story.summary || ""} allowHTML={true} readerMenu={false} />
                <h2>Capítulos</h2>
                <StoryChapters storySlug = {story.slug} chapters = {story.chapters}/>
            </Col>
        </Row>        
        <AdSense.Google
          client={adClient}
          slot={adSlotInArticle}
        />
        <Comments url={`${siteUrl}/stories/${story.slug}`} slug={`stories-${story.slug}`} title={story.title}/>
        </Col>
        </Row>
        <Row id="more-posts-row">
        <h4>Entradas relacionadas</h4>
        </Row>
        <Row>
        <PostsGrid posts={morePosts} />
        </Row>
        <Footer/>
    </Container>
    </>
    )
}

/*

 */

//query StoryQuery($slug: String!, $regexSlug: String!){

export const query = graphql`
  query StoryQuery($slug: String!){
    strapiStories (slug: {eq: $slug}){
        title
        slug
        date
        summary
        finished
        image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 300)
              }
            }
        }
        author {
            name
            slug
            donation
        }
        category {
            name
            slug
        }
        tags {
            name
            slug
        }
        chapters{
            slug
            title
            date
            number
        }
    }
    allStrapiPosts (filter:{ slug:{ne: $slug} }, sort: {fields: date, order: DESC}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
      }
      allStrapiStories (filter:{ slug:{ne: $slug} }, sort: {fields: date, order: DESC}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
      }
  }
`

export default Story
    /*/
    allPageViews(filter: {id: {regex: $regexSlug}}) {
      nodes {
          totalCount
        }
      }
    /*/