import React from "react"
import { Link } from "gatsby"
import Table from 'react-bootstrap/Table'
const StoryChapters = ({chapters, storySlug}) => {

    chapters.sort((a,b)=>{
        return a.number - b.number
    })

    return (
        <Table hover>
            <thead>
            <tr>
                <th><b>Fecha</b></th>
                <th><b>Título</b></th>
            </tr>
            </thead>
            <tbody>
                {chapters?.map((chapter)=>(
                    <tr>
                        <th>{new Date(chapter.date).toLocaleDateString('es-mx',{year: 'numeric', month: 'short',day: 'numeric'})}</th>
                        <th><Link to={`/stories/${storySlug}/${chapter.slug}`}>{chapter.title}</Link>{`  `}</th>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default StoryChapters

